import { FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { Spacer } from "../../components/Spacer";
import { TextField } from "../../components/TextField";
import useLoadingOverlay from "../../hooks/useLoadingOverlay";
import { useAuth } from "../../service/auth/AuthProvider";
import { AuthError } from "../../service/auth/error";
import styles from "./LoginPage.module.scss";
import { datadogLogs } from "@datadog/browser-logs";
import { useEffect } from "react";
import { usePharmacy } from "../../hooks/usePharmacy";

export const LoginPage: React.VFC = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const { signIn, signedIn } = useAuth();
  const { showLoadingOverlay, closeLoadingOverlay } = useLoadingOverlay();
  const history = useHistory();
  const { pharmacy } = usePharmacy();

  useEffect(() => {
    if (signedIn) {
      if (pharmacy === null) {
        history.replace("/pharmacies");
      } else {
        history.replace("/home");
      }
    }
  }, [signedIn]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!userId || !password) {
      setErrorMsg("ユーザーIDもしくはパスワードが違います");
      return;
    }

    showLoadingOverlay();
    signIn(userId, password)
      .then(() => {
        history.push("/pharmacies");
      })
      .catch((e: AuthError) => {
        datadogLogs.logger.error(JSON.stringify(e));
        if (
          ["UserNotFoundException", "NotAuthorizedException"].includes(e.code)
        ) {
          setErrorMsg("ユーザーIDもしくはパスワードが違います");
        } else {
          setErrorMsg("エラーが発生しました。");
        }
      })
      .finally(() => {
        closeLoadingOverlay();
      });
  };

  return (
    <main className={styles.main}>
      <section className={styles.section}>
        <p className={styles.logo}></p>
        <div className={styles.form_field}>
          {errorMsg && (
            <div>
              <p className="error">{errorMsg}</p>
            </div>
          )}
          <form className={styles.sec_login01_in} onSubmit={onSubmit}>
            <TextField
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              placeholder="ユーザーID"
              maxLength={50}
            />
            <Spacer axis="vertical" size={8} />
            <TextField
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="パスワード"
              maxLength={50}
            />
            <Spacer axis="vertical" size={8} />
            <button className={styles.glb_btn01} type="submit">
              ログイン
            </button>
          </form>
        </div>
      </section>
    </main>
  );
};
