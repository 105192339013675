import styles from "./PharmaciesPage.module.scss";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../api/pharmacy_app/me/$api";
import { apiClient } from "../../lib/apiClient";
import { Pharmacy_app_me_pharmacies } from "../../api/@types";
import { usePharmacy } from "../../hooks/usePharmacy";
import { Header } from "../../components/Header";
import { PharmaciesList } from "./components/PharmaciesList";
import { datadogLogs } from "@datadog/browser-logs";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { LDProps } from "launchdarkly-react-client-sdk/lib/withLDConsumer";

const PharmaciesPageContent: React.VFC<LDProps> = (props) => {
  const history = useHistory();
  const { setPharmacy } = usePharmacy();
  const [pharmacies, setPharmacies] = useState<Pharmacy_app_me_pharmacies[]>(
    []
  );

  const pharmacyOnClick = (pharmacy: Pharmacy_app_me_pharmacies) => {
    // 指定された薬局に薬局IDで Launch Darkly のフラグ更新
    props.ldClient?.identify({ key: pharmacy.id });

    setPharmacy(pharmacy);
    history.push("/home");
  };

  useEffect(() => {
    api(apiClient)
      .get()
      .then((result) => {
        setPharmacies(result.body.pharmacies);
      })
      .catch((e) => {
        datadogLogs.logger.error(JSON.stringify(e));
        history.push("/error-500");
      });
  }, []);

  return (
    <div>
      <Header title="薬局選択" />
      <main>
        <section className={styles.sec_select01}>
          <PharmaciesList pharmacies={pharmacies} onClick={pharmacyOnClick} />
        </section>
      </main>
    </div>
  );
};

export const PharmaciesPage = withLDConsumer()(PharmaciesPageContent);
