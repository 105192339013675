import { Link } from "react-router-dom";
import { Header } from "../../components/Header";
import styles from "./QRcodeErrorPage.module.scss";

export const QRcodeErrorPage = () => {
  return (
    <div>
      <Header hasBackButton title="お薬手帳QRコード読み込み" />

      <main>
        <section className={styles.section}>
          <p className={styles.errorText}>
            お薬手帳QRコードの読み込みに
            <br />
            失敗しました。
          </p>

          <div className={styles.buttons}>
            <Link to="/qrcode" className={styles.outlineLink}>
              再撮影する
            </Link>
            <Link to="/" className={styles.outlineLink}>
              ホームへ戻る
            </Link>
          </div>
        </section>
      </main>
    </div>
  );
};
