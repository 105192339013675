import axiosClient from "@aspida/axios";
import axios from "axios";
import { Pharmacy } from "../hooks/usePharmacy";
import { getToken } from "../service/auth";
import { getItemAndParse, KEYS } from "../service/localStorage";

axios.interceptors.request.use(async (config) => {
  const token = await getToken();

  const savedPharmacy = getItemAndParse(
    KEYS.SELECTED_PHARMACY
  ) as Pharmacy | null;

  if (savedPharmacy) {
    config.headers["X-Pharmacy-Id"] = savedPharmacy.id;
  }
  config.headers["Authorization"] = token ? token : "";
  return config;
});

export const apiClient = axiosClient(axios, {
  baseURL: process.env.REACT_APP_API_URL,
});
