import styles from "./QRcodeRegistrationErrorPage.module.scss";
import { Link } from "react-router-dom";
import { Header } from "../../components/Header";
import { usePatient } from "../../hooks/usePatient";

export const QRcodeRegistrationErrorPage: React.VFC = () => {
  const { patient } = usePatient();

  return (
    <div>
      <Header title="エラー" />
      <main>
        <section className={styles.sec_dialog01}>
          <p className={`${styles.type_error} ${styles.sec_dialog01_text}`}>
            {patient && `${patient}さんの`}処方を
            <br />
            登録できません。
          </p>
          <p className={styles.sec_dialog01_btn}>
            <Link to="/home" className={styles.glb_btn03}>
              キャンセル
            </Link>
            <Link to="/qrcode" className={styles.glb_btn01}>
              再登録
            </Link>
          </p>
        </section>
      </main>
    </div>
  );
};
