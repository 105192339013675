import styles from "./ErrorPage.module.scss";

type Props = {
  title: string;
  description: React.ReactNode;
};

export const ErrorPage: React.VFC<Props> = (props) => {
  return (
    <div>
      <main>
        <section className={styles.sec_error01}>
          <ul className={styles.glb_list01_zero}>
            <li>
              <p className={styles.glb_list01_zero_ttl}>{props.title}</p>
              <p className={styles.glb_list01_zero_text}>{props.description}</p>
            </li>
          </ul>
        </section>
      </main>
    </div>
  );
};
