import * as React from "react";
import { signedIn, signIn, signOut } from ".";
import api from "../../api/pharmacy_app/login/$api";
import { usePharmacy } from "../../hooks/usePharmacy";
import { apiClient } from "../../lib/apiClient";

type AuthContextProps = {
  signedIn: boolean;
  signIn: typeof signIn;
  signOut: typeof signOut;
};

export const AuthContext = React.createContext<AuthContextProps>({
  signedIn: false,
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});

export const useAuth = () => React.useContext<AuthContextProps>(AuthContext);

export const SignInErrorCode = {
  UserNotFoundException: "UserNotFoundException",
  NotAuthorizedException: "NotAuthorizedException",
  PasswordResetRequiredException: "PasswordResetRequiredException",
  UserNotConfirmedException: "UserNotConfirmedException",
} as const;

type Props = {
  children?: React.ReactNode;
};

export const AuthProvider: React.VFC<Props> = ({ children }) => {
  const [_signedIn, setSignedin] = React.useState(false);
  const { setPharmacy } = usePharmacy();

  React.useEffect(() => {
    signedIn().then(setSignedin);
  }, []);

  const onSignIn = React.useCallback((username: string, password: string) => {
    return signIn(username, password).then(() => {
      return api(apiClient)
        .get()
        .then(() => {
          setSignedin(true);
        });
    });
  }, []);

  const onSignOut = React.useCallback(() => {
    return signOut().then(() => {
      setSignedin(false);
      setPharmacy(null);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signedIn: _signedIn,
        signIn: onSignIn,
        signOut: onSignOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
